<template>
    <div class="cmp-version-footer">
        <div :class="['cmp-error-message']">
            {{ $t(this.getItem('compareVersionsError')) }}
            <!-- <b>Your subscription has reached the total number of query limits for AI Comparison.</b>
            Please reach out to your subscription admin for increasing your access limit -->
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareVresionsHeader",
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall'
            ])
        }
    }
</script>