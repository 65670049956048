<template>
    <div class="cb-header">
        <div class="cb-header-item cb-header-main">
            <div class="cb-header-left">
                <div class="cb-header-title">{{ $t('frm_i2i_assistant') }}</div>
            </div>
            <div class="cb-header-right"> 
                <div class="cb-header-actions">
                    <div :class="[getItem('mainChatWindowExpanded') ? 'cb-collapse' : 'cb-expand']" @click.prevent="handleExpandCollapse"></div>
                    <div class="cb-close" @click="handleCloseChat"></div>
                </div>     
            </div>
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatHeader",
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'closeChat'
            ]),
            handleCloseChat() {
                this.closeChat();
            },
            handleExpandCollapse() {
                this.setItem({field: 'mainChatWindowExpanded', value: !this.getItem('mainChatWindowExpanded')});
                this.setItem({field: 'showChatHistory', value: this.getItem('mainChatWindowExpanded')});
            }
        }
       
    }
</script>