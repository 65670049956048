<template>
        <div :class="['cmp-smmary-container', getItem('tabPage') == 'compare-summary' ? '': 'hide']">
            <div class="cmp-summary-result">
                <VueShowdown :class="['cmp-summary']" :markdown="this.getItem('diffSummary')" flavor="github" :options="{ emoji: true }"/>     
                <div :class="['cmp-error', getItem('showError') ? '': 'hide']" v-html="getItem('errMessage')"></div>
            </div>
            <CompareRateSummary></CompareRateSummary>
        </div>
</template>
<script>
    import CompareRateSummary from './CompareRateSummary.vue';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareSummary",
        components: {
            CompareRateSummary
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'showCompareSummary',
                'showCopyButton'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall'
            ])
        }
    }
</script>