<template>
    <div :class="['cb-wizard', getItem('wizardStep')]">
        <ChatVersionSelection  v-if="getItem('wizardStep') =='version-selection'"></ChatVersionSelection>
        <ChatLanguageSelection  v-if="getItem('wizardStep')=='language-selection'"></ChatLanguageSelection>
        <ChatFaqSelection  v-if="getItem('wizardStep')=='faq-selection'"></ChatFaqSelection>
        <div v-if="getItem('wizardStep') != 'faq-selection'" :class="['cb-step-action', getItem('wizardStep'),  (Object.keys(getItem('versions')).length==1)? 'one-version':'']">
            <div :class="['cb-btn-next', nextStep =='' ? 'disabled': '']" @click="handleNextClick">{{ $t('frm_next') }}</div>
        </div>
    </div>
</template>
<script>
    import ChatVersionSelection from './ChatVersionSelection';
    import ChatLanguageSelection from './ChatLanguageSelection';
    import ChatFaqSelection from './ChatFaqSelection';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatWizard",
        components: {
            ChatLanguageSelection, 
            ChatFaqSelection, 
            ChatVersionSelection
        },
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ]),
            nextStep() {
                if (this.getItem('wizardStep') == 'version-selection' && this.getItem('versionId')) {
                    return 'language-selection';
                } else if (this.getItem('wizardStep') == 'language-selection' && this.getItem('itemId')) {
                    return 'faq-selection';
                } else {
                    return '';
                }
            }
        },
        methods: {
            ...aiChatMapActions([
                'setItem'
            ]),
            handleNextClick() {
                if(this.nextStep) {
                    this.setItem({field: 'wizardStep', value: this.nextStep});
                }
            }
        }
    }
</script>