<template>
     <div class="cmp-summary-footer">
        <div :class="['cmp-rating']">
            <div class="label">{{  $t('frm_was_this_helpful') }}</div>
            <img v-if="getItem('selectedRating') == '1'" @click="handleSelectRating('-1')" class="thumbsup" src="../../img/thumbsup-fill.svg" :title="$t('frm_like')"/>
            <img v-else @click="handleSelectRating('1')" class="thumbsup" src="../../img/thumbs-up.svg" :title="$t('frm_like')"/>
            <img v-if="getItem('selectedRating') == '0'" @click="handleSelectRating('-1')" class="thumbsdown" src="../../img/thumbsdown-fill.svg" :title="$t('frm_dislike')"/>
            <img v-else @click="handleSelectRating('0')" class="thumbsdown" src="../../img/thumbs-down.svg"  :title="$t('frm_dislike')"/>
            <img  class="copy-clipboar" @click.prevent="handleCopyToClipBoard" src="../../img/icon-copy.svg" :title="$t('frm_copy')"/>
        </div>
    </div>
</template>
<script>
   
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareSummary",
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'showCompareSummary',
                'showCopyButton'
            ]),
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall',
                'updateSummaryRating'
            ]),
            handleSelectRating(rating) {
                const currentRating = this.getItem('selectedRating');
                if (currentRating === rating) {
                    this.setItem({ field: 'selectedRating', value: '-1' });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                } else {
                    this.setItem({ field: 'selectedRating', value: rating });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                }
            },
            handleCopyToClipBoard() {
                var copyText = this.getItem('diffSummary');
                navigator.clipboard.writeText(copyText);
            } 
        }
    }
</script>