<template>
    <div class="cb-search-history-container">
        <div class="cb-search-history" v-click-outside="clickOutsideSearchBoxConfig">
            <div class="cb-search-bar">
                <div class="cb-search-term-wrapper">
                    <SaiInput class="cb-search-term" placeholder="Search chats" :value="getItem('searchTerm')"
                        @input="$event => handleSearchHistory($event)" autocomplete="off" maxlength="255"/>
                </div>
                <div class="cb-search-action">
                    <div class="cb-search-close" @click.prevent="handleCloseSearchHistory"></div>
                </div>
            </div>
            <div class="cb-result-wrapper" v-if="getItem('searchTerm')==''">
                <div  class="cb-result-list" v-for="([category, items], index) in Object.entries(getItem('chatHistory'))" :key="index">
                    <div class="cb-result-list-title">{{ $t(category) }}</div>
                    <div class="cb-result-items">
                        <div class="cb-result-item" v-for="item in items" :key="item.id" 
                        @click.prevent="handleSearchResultItemClick(item)">{{ item.title || item.title_tmp}}</div>
                    </div>
                </div>
            </div>
            <div class="cb-result-wrapper" v-if="getItem('searchTerm')!=''">
                <Loading :active="getItem('searchChatHistoryProcessing')"
                    :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
                    :can-cancel="false"
                    :lock-scroll="true"
                    backgroundColor="none"
                    :opacity="0.9"
                    :z-index=999
                />
                <!-- <div class="cb-searching" v-if="getItem('searchChatHistoryProcessing')">{{ $t('frm_searching') }}</div> -->
                <div class="cb-no-search-result" v-if="!getItem('searchChatHistoryProcessing') && getItem('searchResult').length==0">{{ $t('frm_no_result') }}</div>
                <div class="cb-result-list" v-if="!getItem('searchChatHistoryProcessing') && getItem('searchResult').length>0">
                    <div class="cb-result-items">
                        <div class="cb-result-item" v-for="(item, index) in getItem('searchResult')" :key="index"
                        @click.prevent="handleSearchResultItemClick(item)">{{ item.title || item.title_tmp}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // import {debounce} from '@/plugins/axios';
    import Loading from 'vue-loading-overlay';
    import SaiInput from '@/components/common/SaiInput';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatSearchHistory",
        components: {
            SaiInput,
            Loading
        },
        data() {
            return {
                clickOutsideSearchBoxConfig: {
                    handler: this.clickOutsideSearchBoxHandler,
                    events: ['click']
                },
                chatHistoryScrollTop: 0,
                showActionForChatId: 0,
                activeEditForChatId: 0,
                editTitle:''
            }
        },
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ]),
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'setUpNewChat',
                'loadMoreChatHistory',
                'deleteChatHistory',
                'updateChatTitle',
                'loadChatFromHistory',
                'searchChatHistory'
            ]),
            handleCloseSearchHistory() {
                this.setItem({field: 'showSearchHistory', value: false});
            },
            handleSearchHistory(searchTerm) {
                searchTerm = searchTerm.trim();
                this.setItem({field: 'searchTerm', value: searchTerm});
                if(searchTerm!==''  && searchTerm.length > 2) {
                    this.searchChatHistory(searchTerm);
                }
            }, 
            handleSearchResultItemClick(item) {
                this.loadChatFromHistory(item);
                if(!this.getItem('mainChatWindowExpanded')) {
                    this.setItem({field: 'showChatHistory', value: false});
                }
            },
            clickOutSideActionHandler() {
                this.setItem({field: 'showSearchHistory', value: false});
            }
            // loadSuggest: debounce(async function(value) {
            //     let response = await apiSuggest( this.api, value, this.apiExtraParams, this.apiHeaderContentType );
            //     await this.fillResults(response);
            //     this.searching = true;
            // },1000),
        }
    }
</script>