<template>
    <div class="cmp-version-header">
        <div :class="['cmp-prompt-selection']">
            <div class="label">{{ $t('frm_result_format') }}:</div>
            <div class="cmp-prompt-select-wrapper">
                <Loading :active=" getItem('promptOptionsLoading')"
                    :is-full-page="false" loader="spinner" color='black' :width="20" :height="20"
                    :can-cancel="false"
                    :lock-scroll="true"
                    backgroundColor="none"
                    :opacity="0.9"
                    :z-index=999
                    />
                <SaiSelect
                    :value="getItem('selectedPromptId')"
                    class="compare-prompt"
                    name="compare_prompt"
                    labelBy="id"
                    trackBy="title"
                    :options="getItem('promptOptions')"
                    :placeholder="$t('frm_select_result_format')"
                    @change="handleSelectPrompt"/>
            </div>
        </div>
        <div v-if="this.getItem('tabPage') == 'compare-summary'" class="cmp-version-regenerate"  @click.prevent="handleCompare">{{ $t('frm_refresh') }}</div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import SaiSelect from '@/components/common/SaiSelect';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareVresionsHeader",
        components: {
            SaiSelect,
            Loading
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall',
                'getSecitonDiffSummary'
            ]),
            handleSelectPrompt(event) {
                this.setItem({field: 'selectedPromptId', value: event});
            }, 
            handleCompare() {
                this.setItem({field: 'processAborted', value: false});
                let compareContent = []
                compareContent.push({
                    itemId: this.getItem('toc1').uid,
                    startPage: this.getItem('toc1Selection').startPage,
                    endPage: this.getItem('toc1Selection').endPage,
                    title: this.getItem('toc1Selection').title,
                    itemName: this.getItem('itemName1')
                });
                compareContent.push({
                    itemId: this.getItem('toc2').uid,
                    startPage: this.getItem('toc2Selection').startPage,
                    endPage: this.getItem('toc2Selection').endPage,
                    title: this.getItem('toc2Selection').title,
                    itemName: this.getItem('itemName2')
                });
                const selectedPromptId = this.getItem('selectedPromptId');
                const docId = this.getItem('docId');
                const actionedBy = this.getItem('userId');
                const docName = this.getItem('docName');
                const language = this.getItem('language');
                const subId = this.getItem('subId');
                this.getSecitonDiffSummary({ compareContent, selectedPromptId, docId, actionedBy, subId, docName, language });
                this.setItem({field: 'selectedRating', value: -1});
            }
        }
    }
</script>