<template>
    <div class="cb-step language">
        <div class="cb-step-header">
            <div class="cb-step-icon"></div>
            <div class="cb-step-title">{{ $t('frm_ai_intro') }}</div>
            <div class="cb-step-sub-title">{{ $t('frm_ai_select_language') }}</div>
        </div>
        <div class="cb-step-body">
            <div class="cb-list">
                <ul v-if="getItem('versionId')" class="language">
                    <li v-for="(pdfItem, index) in getLanguageOptions" :key="index" 
                    @click="handleSelectLanguage(pdfItem)" 
                    :class="['with-icon', getItem('itemId') == pdfItem.uid ? 'selected': '']">
                        {{pdfItem.language}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatLanguageSelection",
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ]),
            getLanguageOptions() {
                const selectedVersion = this.getItem('versions')[this.getItem('versionId')];
                return selectedVersion.lang;
            }
        },
        methods: {
            ...aiChatMapActions([
                'setItem'
            ]),
            handleSelectLanguage(pdfItem) {
                this.setItem({field: 'itemId', value: pdfItem.uid});
                this.setItem({field: 'resId', value: pdfItem.res_id});
                this.setItem({field: 'pubId', value: pdfItem.pub_id});
            },
        }
    }
</script>