<template>
    <div class="cmp-cta">
        <div :class="['btn-ai-compare', !activateCompareButton ? 'inactive': '',  showCompareButton ? '':'hide']" @click.prevent="handleCompare">{{  $t('frm_compare') }}</div>
        <div :class="['btn-ai-compare', 'btn-new-compare', showNewCompareButton ? '':'hide']" @click.prevent="handleNewCompare">{{ $t('frm_new_compare') }}</div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareHeaderRightCTA",
        components: {
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'showTOCSelectoin',
                'showCompareSummary',
                'showCopyButton',
                'showBackButton',
                'showRatingOption',
                'showRegenerateSummary'
            ]),
            compareReady: function() {
                return (typeof this.getItem('toc1Selection').id !== "undefined") && (typeof this.getItem('toc2Selection').id !== "undefined");
            },
            showCompareButton() {
                return this.getItem('activeTab') == 'compare-versions' && this.getItem('tabPage') == 'compare-toc';
            },
            activateCompareButton() {
                return this.getItem('activeTab') == 'compare-versions' && this.getItem('tabPage') == 'compare-toc'
                && (typeof this.getItem('toc1Selection').id !== "undefined") && (typeof this.getItem('toc2Selection').id !== "undefined");
            },
            showNewCompareButton() {
                return (this.getItem('activeTab') == 'compare-versions' && this.getItem('tabPage') == 'compare-summary') || (this.getItem('activeTab') == 'compare-history');
            }
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'closeCompare',
                'getSecitonDiffSummary',
                'updateSummaryRating',
                'abortApiCall',
                'setActivePage'
            ]),
            handleCloseCompare() {
                this.closeCompare();
            },
            getTOC: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['toc'];
                }
                return [];
            },
            getTOCArtefactName: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['artefact_name'];
                }
                return '';
            },
            handleTitleClick(tocItem, tocIndex) {
                if(tocIndex == 0) {
                    this.setItem({field: 'toc1Selection', value: tocItem});
                }
                if(tocIndex == 1) {
                    this.setItem({field: 'toc2Selection', value: tocItem});
                }
            },
            handleClose() {
                this.setItem({field: 'loading', value: false})
                this.setItem({field: 'diffSummaryId', value: 0})
                this.setItem({field: 'selectedRating', value: -1})
                this.setItem({field: 'toc1Selection', value: {}})
                this.setItem({field: 'toc2Selection', value: {}})
                this.setItem({field: 'showCompareWindow', value: false});
                this.setItem({field: 'processAborted', value: true});
                this.abortApiCall();
            },
            handleCompare() {
                if(this.compareReady) {
                    this.setItem({field: 'processAborted', value: false});
                    let compareContent = []
                    compareContent.push({
                        itemId: this.getItem('toc1').uid,
                        startPage: this.getItem('toc1Selection').startPage,
                        endPage: this.getItem('toc1Selection').endPage,
                        title: this.getItem('toc1Selection').title,
                        itemName: this.getItem('itemName1')
                    });
                    compareContent.push({
                        itemId: this.getItem('toc2').uid,
                        startPage: this.getItem('toc2Selection').startPage,
                        endPage: this.getItem('toc2Selection').endPage,
                        title: this.getItem('toc2Selection').title,
                        itemName: this.getItem('itemName2')
                    });
                    const selectedPromptId = this.getItem('selectedPromptId');
                    const docId = this.getItem('docId');
                    const actionedBy = this.getItem('userId');
                    const docName = this.getItem('docName');
                    const language = this.getItem('language');
                    const subId = this.getItem('subId');
                    this.getSecitonDiffSummary({ compareContent, selectedPromptId, docId, actionedBy, subId, docName, language });
                    this.setItem({field: 'selectedRating', value: -1})
                }
            },
            handleSelectPrompt(event) {
                this.setItem({field: 'selectedPromptId', value: event});
            },
            handleSelectRating(rating) {
                const currentRating = this.getItem('selectedRating');
                if (currentRating === rating) {
                    this.setItem({ field: 'selectedRating', value: '-1' });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                } else {
                    this.setItem({ field: 'selectedRating', value: rating });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                }
            },

            handleCopyToClipBoard() {
                var copyText = this.getItem('diffSummary');
                navigator.clipboard.writeText(copyText);
            }, 
            handleNewCompare() {
                this.setActivePage({activeTab: 'compare-versions', tabPage: 'compare-toc'});
            }
        }
    }
</script>