<template>
    <div class="cb-header-secondary">
        <div class="cb-header-left">
            <div :class="['cb-expand-history', getItem('showChatHistory') ? 'hide': '']" @click.prevent="handleShowHistory"></div>
        </div>
        <div class="cb-header-right">
            <div class="cb-versions">
                <div v-if="getSelectedVersion" class="cb-selected-version"  @click="handleToggleVersionOptions">{{ $t('frm_version') }}: {{  getSelectedVersion.version }}</div>
                <div v-else class="cb-selected-version"  @click="handleToggleVersionOptions">{{ $t('frm_select_version') }}</div>
                <div v-if="getItem('showVersionOptions')" class="cb-version-list">
                    <ul v-if="Object.keys(getItem('versions')).length" class="version">
                        <li v-if="getSelectedVersion" @click="handleToggleVersionOptions">{{ $t('frm_version') }}: {{  getSelectedVersion.version }}</li>
                        <template v-for="([key, version], index) in Object.entries(getItem('versions'))">
                            <li v-if="getItem('versionId') != key" :key="index" @click="handleSelectVersion(version)">{{ version.version }} </li>
                        </template>
                    </ul>
                    <div class="cb-version-action">
                        <div class="cb-version-collapse" @click="handleToggleVersionOptions"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatHeaderSecondary",
        computed: {
            ...aiChatMapGetters([
                'getItem',
                'getSelectedVersion'
            ])
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'setUpNewChat'
            ]),
            handleSelectVersion(version) {
                this.setUpNewChat();
                this.setItem({field: 'wizardStep', value: 'version-selection'});
                this.setItem({field: 'versionId', value: version.versionId});
                this.setItem({field: 'versionName', value: version.version});
            },
            handleToggleVersionOptions() {
                this.setItem({field: 'showVersionOptions', value: !this.getItem('showVersionOptions')});
                if(!this.getItem('mainChatWindowExpanded')) {
                    this.setItem({field: 'showChatHistory', value: false});
                }
            },
            handleShowHistory() {
                this.setItem({field: 'showChatHistory', value: true});
            }
        }
    }
</script>