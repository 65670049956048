<template>
    <div v-if="getItem('showMainChatWindow')" class="cb-container">
        <div :class="['cb', getItem('mainChatWindowExpanded') ? 'expanded' : 'collapsed']">
            <ChatHeader></ChatHeader>  
            <div class="cb-body">
                <ChatHistory v-if="getItem('showChatHistory')"></ChatHistory>
                <ChatSearchHistory v-if="getItem('showSearchHistory')"></ChatSearchHistory>
                <div class="cb-main">
                    <ChatHeaderSecondary></ChatHeaderSecondary>
                    <div class="cb-main-wrapper">
                        <ChatWizard v-if="showChatWiardStep"></ChatWizard>
                        <ChatMessaging v-if="showChatMessaging"></ChatMessaging>
                        <ChatPrompt v-if="showChatPrompt"></ChatPrompt>
                    </div>
                </div>
                <ChatDeleteConfirmModal v-if="getItem('showConfirmDeleteModal')"></ChatDeleteConfirmModal>
            </div>
        </div>
    </div>
</template>

<script>
    import ChatHistory from './ChatHistory';
    import ChatSearchHistory from './ChatSearchHistory';
    import ChatWizard from './ChatWizard';
    import ChatDeleteConfirmModal from './ChatDeleteConfirmModal';
    import ChatHeader from './ChatHeader';
    import ChatHeaderSecondary from './ChatHeaderSecondary';
    import ChatMessaging from './ChatMessaging';
    import ChatPrompt from './ChatPrompt';
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "index",
        components: {
            ChatHistory,
            ChatSearchHistory,
            ChatWizard,
            ChatMessaging,
            ChatPrompt,
            ChatHeader,
            ChatHeaderSecondary,
            ChatDeleteConfirmModal
        },
        computed: {
            ...aiChatMapGetters([
                'getItem',
                'showChatPrompt',
                'showChatMessaging',
                'showChatWiardStep'
            ])
        }
    }
</script>

<style scoped lang="scss">
@import "scss/style.scss";
</style>