<template>
    <div :class="['section-selection', getItem('tabPage') == 'compare-toc' ? '' : 'hide']">
        <div class="toc-wrapper">
            <div class="toc-title-left">{{ getTOCArtefactName(1) }}</div>
            <div class="toc-left">
                <TOC v-for="section in getTOC(1)" :key="section.id" :node="section" :selected-item="getItem('toc1Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 0)"></TOC>
            </div>
        </div>
        <div class="toc-wrapper">
            <div class="toc-title-right">{{  getTOCArtefactName(2) }}</div>
            <div class="toc-right">
                <TOC v-for="section in getTOC(2)" :key="section.id" :node="section" :selected-item="getItem('toc2Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 1)"></TOC>
            </div>
        </div>

    </div>
</template>
<script>
    import TOC from './TOC';
    import { createNamespacedHelpers } from 'vuex';
       const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareTOC",
        components: {
            TOC
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ]),
            showTOCSelection() {
                return this.getItem('activeTab') == 'compare-versions' && this.getItem('tabPage') == 'toc';
            }
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall'
            ]),
            getTOCArtefactName: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['artefact_name'];
                }
                return '';
            },
            handleTitleClick(tocItem, tocIndex) {
                if(tocIndex == 0) {
                    this.setItem({field: 'toc1Selection', value: tocItem});
                }
                if(tocIndex == 1) {
                    this.setItem({field: 'toc2Selection', value: tocItem});
                }
            },
            getTOC: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['toc'];
                }
                return [];
            },
        }
    }
</script>