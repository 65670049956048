<template>
    <div class="cb-step faq">
        <div class="cb-step-header">
            <div class="cb-step-icon"></div>
            <div class="cb-step-title">{{ $t('frm_ai_intro') }}</div>
            <div class="cb-step-sub-title">{{ $t('frm_ai_select_faq') }} {{ getItem('versionName') }}</div>
        </div>
        <div class="cb-step-body">
            <Loading :active="getItem('loadFaqProcessing')"
                :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
                :can-cancel="false"
                :lock-scroll="true"
                backgroundColor="none"
                :opacity="0.9"
                :z-index=999
            />
            <div class="cb-list">
                <ul v-if="getItem('itemId')" class="faq">
                    <li v-for="(faq, index) in getFaqs" :key="index" @click="handleFaqSelection(faq)" :class="['with-icon', getItem('faq') == faq ? 'selected': '']" >{{faq}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import i18n from "@/plugins/i18n";
    import Loading from 'vue-loading-overlay';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatFaqSelection",
        components: {
            Loading
        },
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ]),
            getFaqs() {
                const faqs = this.getItem('faqs');
                const itemId = this.getItem('itemId');
                if(faqs[itemId]) {
                    // Step 1: Split the string into questions and answers based on the pattern "Qx:"
                    const questionsAndAnswers = faqs[itemId]['faq'].split(/Q\d+:/);
                    // Step 2: Extract questions (skip the first empty element from split)
                    return questionsAndAnswers
                    .slice(1) // Slice off the first empty element
                    .map(item => item.split("\n")[0].trim()); // Split by newline and get the first line for the question
                }
                return [i18n.t('frm_faq1'), i18n.t('frm_faq2'), i18n.t('frm_faq3'), i18n.t('frm_faq4'), i18n.t('frm_faq5')];
            }
        },
        methods: {
            ...aiChatMapActions([
                'setItem'
            ]),
            handleFaqSelection(faq) {
                this.setItem({field: 'faq', value: faq});
                this.setItem({field: 'userMessage', value: faq});
            }
        }
    }
</script>