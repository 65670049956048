<template>
    <div class="cmp-tabs">
        <div class="cmp-tabs-header">
            <div class="cmp-tabs-header-left">
                <div class="cmp-tab-items">
                    <div :class="['cmp-tab-item', 'compare-versions', getItem('activeTab') == 'compare-versions' ? 'active': '']"
                        @click="setActivePage({activeTab: 'compare-versions', tabPage: 'compare-toc'})">
                        {{ $t('frm_compare_versions') }}
                    </div>
                    <div :class="['cmp-tab-item', 'compare-history', getItem('activeTab') == 'compare-history' ? 'active': '']"
                        @click="setActivePage({activeTab: 'compare-history', tabPage: ''})">
                        {{ $t('frm_compare_history') }}
                    </div>
                </div>
            </div>
            <div class="cmp-tabs-header-right">
                <slot name="headerright"></slot>
            </div>
        </div>
        <div class="cmp-tabs-body">
            <slot name="body"></slot>
        </div>
    </div>
</template>
<script>
       import { createNamespacedHelpers } from 'vuex';
       const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareTabs",
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall',
                'setActivePage'
            ])
        }
    }
</script>