<template>
    <div :style="nodeMargin">
      <div show class="toc-item">
        <label :class="labelClass" @click.prevent="handleTitleClick"  v-html="node.title"></label>
        <span v-if="hasChildren" :class="toggleChildrenIcon" @click="toggleChildren" @keypress="toggleChildren"/>
      </div>
      <div v-if="hasChildren" v-show="showChildren">
        <TOC v-for="child in node.children" :key="child.id" :node="child" :selected-item="selectedItem" :spacing="spacing + 10" @titleClick="handleTitleClickChild"/>
      </div>
    </div>
  </template>
  <script>
  export default {
        name: 'TOC',
        props: {
            node: { type: Object, required: true },
            spacing: { type: Number, default: 0 },
            selectedItem: { type: Object, default: () => {}, required: false },
        },
        data() {
            return {
                showChildren: false,
                labelClass: 'toc-item-label'
            }
        },
        computed: {
            nodeMargin() {
                return {
                    'margin-left': `${this.spacing}px`
                }
            },
            hasChildren() {
                const { children  } = this.node;
                return children && children.length > 0;
            },
            toggleChildrenIcon() {
                return this.showChildren ? 'toggle-children si si-minus' : 'toggle-children si si-plus'
            }
        },
        methods: {
            toggleChildren() {
                this.showChildren = !this.showChildren
            },
            handleTitleClick() {
                this.$emit('titleClick',this.node);
            },
            handleTitleClickChild(node) {
                this.$emit('titleClick', node)
            }
        },
        watch: {
            selectedItem: function(newSeletedItem) {
                if(typeof newSeletedItem.id !== "undefined" && this.node.id ==  newSeletedItem.id  ) {
                    this.labelClass = 'toc-item-label selected';
                } else {
                    this.labelClass = 'toc-item-label';
                }
            }
        }

    }
</script>