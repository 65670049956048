<template>
    <div class="cmp-header">
        <div class="cmp-header-left">
            <div class="cmp-header-back" @click.prevent="handleBack">{{ $t('frm_back') }}</div>
        </div>
        <div class="cmp-header-middle">
            <div class="cmp-header-title">{{ getTitle }}</div>
        </div>
        <div class="cmp-header-right">
            <div class="cmp-header-actions">
                <div class="cmp-close" @click="handleClose"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import i18n from "@/plugins/i18n";
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareHeader",
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ]),
            getTitle() {
                if (this.getItem('activeTab') == 'compare-history') {
                    return i18n.t('frm_compare_history');
                } else if(this.getItem('activeTab') == 'compare-versions' && this.getItem('tabPage') == 'compare-summary') {
                    return i18n.t('frm_compare_result');
                } else if(this.getItem('activeTab') == 'compare-versions' && this.getItem('tabPage') == 'compare-toc') {
                    return i18n.t('frm_choose_version');
                } else {
                    return '';
                }
            }
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall',
                'setActivePage'
            ]),
            handleClose() {
                this.setItem({field: 'showCompareWindow', value: false});
                this.setItem({field: 'processAborted', value: true});
                this.abortApiCall();
            },
            handleBack() {
                console.log(this.getItem('prevActiveTabs'));
                if(this.getItem('prevActiveTabs').length > 0) {
                    let prevActiveTabs = this.getItem('prevActiveTabs');
                    const prevActiveTab = prevActiveTabs[0].activeTab;
                    const prevTabPage = prevActiveTabs[0].tabPage;
                    console.log(prevActiveTabs);
                    prevActiveTabs = prevActiveTabs.slice(1);
                    console.log(prevActiveTabs, prevActiveTab, prevTabPage);
                    this.setItem({field: 'prevActiveTabs', value: prevActiveTabs});
                    this.setItem({field: 'activeTab', value: prevActiveTab});
                    this.setItem({field: 'tabPage', value: prevTabPage});
                } else {
                    this.setItem({field: 'showCompareWindow', value: false});
                    this.setItem({field: 'processAborted', value: true});
                    this.abortApiCall();
                }
            }
        }
    }
</script>