<template>
    <div class="cb-step version">
        <div class="cb-step-header">
            <div class="cb-step-icon"></div>
            <div class="cb-step-title">{{ $t('frm_ai_intro') }}</div>
            <div class="cb-step-sub-title">{{$t('frm_ai_select_version', [getItem('docName')])}}</div>
        </div>
        <div class="cb-step-body">
            <div class="cb-list">
                <ul v-if="Object.keys(getItem('versions')).length" :class="['version', (Object.keys(getItem('versions')).length==1)? 'one-version': '']">
                    <li v-for="([key, version], index) in Object.entries(getItem('versions'))"  :class="['with-icon', getItem('versionId') == version.versionId ? 'selected': '']" :key="index" @click="handleSelectVersion(version)">
                        <div class="label">{{ version.version }}</div>
                    </li>
                </ul>
                <div v-else class="cb-no-versions">{{ $t('frm_no_version_for_chat') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatVersionSelection",
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiChatMapActions([
                'setItem'
            ]),
            handleSelectVersion(version) {
                this.setItem({field: 'versionId', value: version.versionId});
                this.setItem({field: 'versionName', value: version.version});
            }
        }
    }
</script>