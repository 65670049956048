<template>
    <div :class="['cmp-history-container',  getItem('activeTab') == 'compare-history'? '' : 'hide']">
        {{ $t('frm_compare_history') }}
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareHistory",
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall'
            ])
        }
    }
</script>