<template>
    <div class="cb-prompt-container">
        <div class="cb-prompt">
            <div :class="['cb-prompt-wrapper', (getItem('waitingReply') || getItem('userMessage').trim() == '') ? 'waiting-reply' : '']">
                <div class="cb-prompt-text">
                    <SaiTextArea 
                        :disabled="getItem('waitingReply')" 
                        :placeholder="$t('frm_as_i2i_assistant')" 
                        name="userMessage" id="usermessage" 
                        :value="getItem('userMessage')" 
                        @input="$event => setItem({field: 'userMessage', value: $event})" 
                        autocomplete="off" 
                        @keydown.enter.exact.prevent="handleSendPrompt"
                    />
                </div>
                <div class="cb-prompt-action">
                    <div class="cb-btn-send" @click="handleSendPrompt"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import SaiTextArea from '@/components/common/SaiTextArea';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatPrompt",
        components: {
            SaiTextArea
        },
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'sendPromptToAI',
                'updateQuotaUsed'
            ]),
            handleSendPrompt() {
                let message = this.getItem('userMessage');
                if(message.trim()!=='' && !this.getItem('waitingReply')) {
                    this.setItem({field: 'wizardStep', value: 'chat-messaging'});
                    this.setItem({field: 'userMessage', value: ''});
                    this.sendPromptToAI(message);
                }
            },
        }
    }
</script>